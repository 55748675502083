import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Divider, Grid, List, ListItem, ListItemSecondaryAction, Typography } from '@mui/material';

// assets
import NotificationService from 'service/notification.service';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

// eslint-disable-next-line react/prop-types
const NotificationList = ({ listNotif, notifStore }) => {
    const theme = useTheme();

    const handleClick = (res) => {
        NotificationService.readNotification(res.id).then(() => {
            if (res.redirect === null) {
                return null;
            } else {
                window.location.href = res.redirect;
            }
        });
    };

    return (
        <List
            sx={{
                maxWidth: '350px',
                py: 0,
                borderRadius: '10px',
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                }
            }}
        >
            {
                // eslint-disable-next-line react/prop-types
                listNotif?.length === 0 || notifStore?.length === 0 ? (
                    <ListItemWrapper>
                        <Typography textAlign={'center'} sx={{ paddingRight: '0' }} align="center" fontSize="12px" variant="subtitle2">
                            You have no new notifications at this time.
                        </Typography>
                    </ListItemWrapper>
                ) : (
                    <>
                        {
                            // eslint-disable-next-line react/prop-types
                            listNotif?.map((res) => (
                                <React.Fragment key={res.id}>
                                    <ListItemWrapper
                                        onClick={() => handleClick(res)}
                                        sx={{ background: res.read_at === null ? theme.palette.primary.light : 'none' }}
                                    >
                                        <ListItem alignItems="center">
                                            <Typography
                                                sx={{ maxWidth: '205px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                                                fontWeight="bold"
                                                color="#1D243C"
                                                pb={1}
                                            >
                                                {res.title}
                                            </Typography>
                                            <ListItemSecondaryAction>
                                                <Grid container justifyContent="flex-end">
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            textAlign={'right'}
                                                            fontSize={'10px'}
                                                            sx={{
                                                                position: 'relative',
                                                                top: '-10px',
                                                                maxWidth: '75px',
                                                                whiteSpace: 'normal',
                                                                wordWrap: 'break-word'
                                                            }}
                                                            variant="caption"
                                                            display="block"
                                                            gutterBottom
                                                        >
                                                            {res.created_at_hrf}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Grid container direction="column" className="list-container">
                                            <Grid item xs={12} sx={{ pb: 2 }}>
                                                <Typography
                                                    sx={{ maxWidth: '340px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                                                    variant="subtitle2"
                                                >
                                                    {res.content}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemWrapper>
                                    <Divider />
                                </React.Fragment>
                            ))
                        }
                    </>
                )
            }
        </List>
    );
};

NotificationList.propTypes = {
    listNotif: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            redirect: PropTypes.string,
            read_at: PropTypes.string,
            created_at_hrf: PropTypes.string.isRequired
        })
    ).isRequired,
    notifStore: PropTypes.arrayOf(PropTypes.any)
};

export default NotificationList;
